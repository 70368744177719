import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import SEO from '../components/seo';
import Footer from '../sections/Footer';
import Wrap from '../components/Wrap';

const NotFoundPage = () => {
  const [aosInit, setAosInit] = useState(false);

  useEffect(() => {
    if (!aosInit) {
      AOS.init({
        duration: 600,
        easing: 'ease-in-sine',
      });
      setAosInit(true);
    } else {
      AOS.refreshHard();
    }
  });

  return (
    <>
      <SEO title="page Not found" />
      <Wrap>
        <h2>404</h2>
        <h1>Not found</h1>
        <p>
          The page you're looking for is not here. Please go back to the <a href="/">Homepage</a>
        </p>
      </Wrap>
      <Footer />
    </>
  );
};
export default NotFoundPage;
